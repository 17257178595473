<template>
  <RegisterContainer :header="$t('message.what-is-your-name')" @next="next()">
    <InputWrapper
      class="mt-8"
      name="firstName"
      :label="$t('message.firstName')"
      type="text"
      :error="hasError('firstName')"
      :touched="touched('firstName')"
      :placeholder="$t('message.firstName')"
      :hint="hint('firstName')"
      v-model="firstName"
      @input="validateForm()"
      @blur="changed('firstName')"
    />
    <InputWrapper
      class="mt-8 flex-grow"
      name="FamilyName"
      :label="$t('message.familyName')"
      type="text"
      :error="hasError('familyName')"
      :touched="touched('familyName')"
      :placeholder="$t('message.familyName')"
      :hint="hint('familyName')"
      v-model="familyName"
      @input="validateForm()"
      @blur="changed('familyName')"
    />
  </RegisterContainer>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import RegisterContainer from "@/components/register/RegisterContainer.vue";

export default {
  name: "NameForm",
  components: {
    InputWrapper,
    RegisterContainer
  },
  data: () => {
    return {
      firstName: "",
      familyName: "",
      error: "",
      validation: {},
      showAllErrors: false,
      step: null
    };
  },
  beforeMount() {
    this.step = this.$route.meta.step;
    if (
      this.$route.name != this.$store.getters["auth/getNewUserValidLocation"] &&
      this.$store.getters["auth/getNewUserStep"] < this.step - 1
    ) {
      this.$router.replace({
        name: this.$store.getters["auth/getNewUserValidLocation"]
      });
    }
  },
  mounted() {
    this.firstName = this.$store.getters["auth/getNewUserName"] || "";
    this.familyName = this.$store.getters["auth/getNewUserFamilyName"] || "";
  },
  computed: {
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
      this.$forceUpdate();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (this.firstName.replaceAll(" ", "") == "") {
        this.addError("firstName", "The name can't be empty");
        valid = false;
      } else {
        this.clearError("firstName");
      }
      if (this.familyName.replaceAll(" ", "") == "") {
        this.addError("familyName", "The family name can't be empty");
        valid = false;
      } else {
        this.clearError("familyName");
      }
      return valid;
    },
    next() {
      this.showAllErrors = true;
      const valid = this.validateForm();
      if (valid) {
        this.$store.dispatch("auth/setNewUserName", this.firstName.trim());
        this.$store.dispatch(
          "auth/setNewUserFamilyName",
          this.familyName.trim()
        );
        this.$store.dispatch("auth/setNewUserStepCompleted", this.step);
        this.$router.push({ name: "ContactPreferences" });
      } else {
        this.response = "Please verify the form";
      }
    }
  }
};
</script>
