<template>
  <div
    id="register-container"
    :class="{ desktop: isDesktop }"
    class="mx-auto flex flex-grow flex-col w-full"
  >
    <h2 class="mx-auto flex justify-center">
      {{ header }}
    </h2>
    <div v-if="subtext" class="font-body mx-auto">
      {{ subtext }}
    </div>
    <form class="h-full flex flex-col" @submit.prevent="$emit('next')">
      <div class="flex-grow">
        <slot />
      </div>
      <button type="submit" class="btn btn-lg btn-default my-4">
        <span class="mx-auto" v-if="!loading">
          {{ $t("message.continue") }}
        </span>
        <div class="loading w-6 h-6" v-else>
          <Spinner class="sm-button" />
        </div>
      </button>
      <NeedHelp />
    </form>
  </div>
</template>

<script>
import NeedHelp from "@/components/register/NeedHelp.vue";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  name: "RegisterContainer",
  components: {
    NeedHelp,
    Spinner
  },
  props: {
    header: {
      type: String,
      required: true
    },
    subtext: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    }
  }
};
</script>

<style lang="postcss" scoped>
#register-container {
  min-height: 450px;
}
.desktop {
  min-height: 550px;
}
</style>
